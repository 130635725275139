import React from 'react';

const TermOfUse = React.lazy(() => import('./Dashboard/TermOfUse/Index'));
const DashboardControl = React.lazy(() => import('./Dashboard/Control'));
const ViewFile = React.lazy(() => import('./ERP/Document/ViewFile/Index'));


const route = [
    { path: '/', exact: true, name: 'Configuration', component: DashboardControl },
    { path: '/viewfile/:id', exact: true, name: 'Lecture de fichier', component: ViewFile },
    { path: '/term-of-use', exact: true, name: 'Conditions d\'utilisation', component: TermOfUse },

];

export default route;
